import React, { useEffect, useState, useRef, } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Card, Col, Row, Form, Input, Select, Button, Spin, Checkbox, Flex, Modal, Typography,InputNumber,TimePicker} from 'antd';
import { message as messageApi } from 'antd';
import { Message } from "../../utility/MessageUtil";
import SignatureModal from './Cli-Emp-Signatures';
import AxiosInstance from "../../services/axiosInstance";
import { CarTwoTone, PhoneTwoTone, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Toaster } from "../../utility/Toaster";
import { GetlocationFromLocalStorage } from "../../utility/CompanyDetails";
import fetchGeolocation from "../../utility/CurrentLocation";
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
const format = 'h:mm A';
const { Meta } = Card;
const { TextArea } = Input;
const { Title } = Typography;
const { confirm } = Modal;
const chartingAr =[
    { opt: 0, form: 'min' },
    { opt: 5, form: 'min' },
    { opt: 10, form: 'min' },
    { opt: 15, form: 'min' },
    { opt: 20, form: 'min' },
    { opt: 25, form: 'min' },
    { opt: 30, form: 'min' },
    { opt: 35, form: 'min' },
    { opt: 40, form: 'min' },
    { opt: 45, form: 'min' },
    { opt: 50, form: 'min' },
    { opt: 55, form: 'min' },
    { opt: 60, form: 'min' }
   ]
const { Option } = Select;
function ScheduleDetails() {
  const byPassEVVCoordinates =  localStorage.getItem("byPassEVVCoordinates");
  const GeoFenceHideMessage = localStorage.getItem("GeoFenceHideMessage") === "true";
      const clientSigCanvas = useRef();
    const { t } = useTranslation();
    const uType = localStorage.getItem("type");
    const location = useLocation();
    const history = useHistory();
    const selectedClient = location.state.selectedClient;
    const ClientEmpData = location.state.userDetails;
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    const [warningLoading, setWarningLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [outComeList, setOutComeList] = useState([]);
    const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [showClientSignature, setShowClientSignature] = useState(false);
    const [getDataForClientPin, setDataForClientPin] = useState(null);
    const [showClientSignatureBox, setShowClientSignatureBox] = useState(false);
    // const startEndTimeRestriction = JSON.parse(localStorage.getItem('startEndTimeRestriction'));
    const [getPinValue, setPinValue] = useState('');
    const [schData, setSchData] = useState({
        _id: selectedClient._id,
        eTime: dayjs().format('hh:mm A'),//selectedClient.eETime || selectedClient.eTime,
        sTime: selectedClient.aSTime || selectedClient.sTime,
        eDate: dayjs(selectedClient.eDate).add(1, 'day').format('MM-DD-YYYY'),
        mileage: selectedClient.mileage || "",
        hssType: selectedClient.hssType || "",
        remoteDirectOptions: '',
        clientId: selectedClient.clientId ? selectedClient.clientId._id : '',
        addressList: selectedClient.clientId ? selectedClient.clientId.addressList : [],
        temp:selectedClient.temp || "",
        notifySupervisor: false,
        pulseRate:selectedClient.pulseRate || "",
        resRate:selectedClient.resRate || "",
        bpValue:selectedClient.bpValue || "",
        satSet:selectedClient.satSet || "",
        chartingValue:selectedClient.chartingValue || "",
        notes: [
            {
                note: '',
                addedBy: ''
            }
        ],
        services: [],
        tasks: [],
        eSign: "",
        lat: "",
        lng: ""
    });

    // const handleGoBack = () => {
    //     history.goBack();
    // };

    useEffect(() => {
        resetSpecificFields();
        setServiceList([]);
        setOutComeList([]);
        if (selectedClient) {
            getSchServices()
            getOneSchedule(selectedClient._id)
            fetchGeoloc();
        }
    }, [selectedClient]);

    useEffect(() => {
        fetchGeoloc();
      }, []);

      const fetchGeoloc = async () => {
        try {
          const location = JSON.parse(localStorage.getItem("location"));
    
          if (location) {
            setPosition({
              latitude: parseFloat(location.lat),
              longitude: parseFloat(location.long),
            });
          } else {
            const fetchedPosition = await fetchGeolocation();
            setPosition({
              latitude: fetchedPosition.latitude,
              longitude: fetchedPosition.longitude,
            });
          }
        } catch (error) {
            Toaster(
                "error",
                "Error fetching geolocation:",
                error.message
              );
        //   console.error("Error fetching geolocation:", error.message);
        }
      };

    const onChange = (value,option) => {
        console.log(`selected ${value}`);
        const lat = option?.lat;
        const lng = option?.lng;
        position.latitude = lat;
        position.longitude = lng;
      };

    const getSchServices = () => {
        setWarningLoading(true);
        const url = `emp/careplan-service?schedule_id=${selectedClient._id}`;
        AxiosInstance.get(url)
            .then(res => {
                setWarningLoading(false);
                if (res.data.success) {
                    // Set the fetched data to the state
                    if (res.data.data.careplan.length) {
                        const taskList = [];
                        setServiceList(res.data.data.careplan);
                        res.data.data.careplan.forEach(x => {
                            taskList.push(x.task);
                        });
                        
                        if(!selectedClient?.careplanControl) handleCheckboxChange(taskList, "all");
                        // if(!selectedClient?.defaultCareplan && state != 'CA') handleCheckboxChange(taskList, "all");
                        // handleCheckboxChange(taskList, "all");
                    }
                    if (res.data.data.outcome_section) {
                        const serviceList = []
                        setOutComeList(res?.data?.data?.outcome_section);
                        res.data.data.outcome_section.map(x => {
                            x.note = "";
                            serviceList.push(x.outcome + ' ' + x.qualifier)
                        });
                        setSchData(prevState => ({ ...prevState, services: serviceList }));
                    }

                } else {
                    messageApi.info(t(`Care plan doesn't exists against this client`));
                }
            })
            .catch(error => {
                setWarningLoading(false);
                // Toaster("error", "Error", error.error);
            });
    };
    const [getHssDetails, setHssDetails] = useState(null);


    const getOneSchedule = (id) => {
        let local = 'sch/' + id;
        AxiosInstance.get(local)
            .then(response => {
                setLoading(false);
                setHssDetails(response.data.data);
            })
            .catch(error => {
                setHssDetails('');
                console.error("Error fetching data:", error);
            });
    }


    const onCheckEndWarning = async () =>{
        if(selectedClient?.careplanControl && serviceList.length !== 0 && schData.tasks?.length === 0){
        // if(selectedClient?.careplanControl && schData.tasks.length == 0){
            Toaster("error", 'Please select atleast one service');
            return
        }
        if (!byPassEVVCoordinates) {
        if(!position.latitude){
            Toaster("error", t("Your device is unable to pick your location, Please check your device settings"));
            return
        }
      }
         if(selectedClient?.service?.configuration?.is_chart){
      if(!schData.notes[0].note || schData.notes[0].note.trim() === '') return;
    }
    if (schData?.notifySupervisor && !schData.notes[0].note && schData.notes[0].note.trim() === '') {
      Toaster("error","Notes are required for notification");
      return;
    }

        setLoading(true);
           let obj = {
             _id: schData._id,
             lat: position.latitude,
             lng: position.longitude,
             clientId: schData?.clientId._id,
           };
        if (schData) {
          const endPoint = "sch-end/check";
          AxiosInstance.put(endPoint, obj)
            .then(({ data }) => {
              setLoading(false);
              if (!data.success) {
                if (data?.message === "Auth doesn't Exists.") {
                    Message.error(data?.message)
                  }
                const allInactive = data?.addressList?.every(location => !location.active);
                if(allInactive){
                    if(data.message.length === 0) submitSchedule();
                    else  endWarningModal(data);
                } else {
                    if(!data.success) endWarningModal(data);
                     else submitSchedule(data,schData)
                  }
              
              } else {
                if(data?.communityService) submitSchedule();
                else {
                  if (data?.addressList?.length) {
                    if (!byPassEVVCoordinates) {
                      if (!position.latitude) {
                        setLoading(false);
                        Toaster("error", t("Your device is unable to pick your location, Please check your device settings"));
                        return;
                      }
                    }
                    endWarningModal(data);
                  } else {
                    submitSchedule(data,schData);
                  }
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error fetching data:", error);
            });
        }
      }
      
    const endWarningModal = (message, obj) => {  
        if (getHssDetails?.isHssType) {
            if (!schData.hssType) {
                setWarningLoading(false);
                messageApi.error(t('Contact Type is required'));

                return;
            }
        }  
        message?.message[0].message ==="You are out of the Geo Fence. Please contact support." || message?.message[0].message === "The Geo Coordinates doesn't exists for client address, Please contact your support"
          ? Modal.confirm({
              title: message?.message[0].message,
              confirmLoading: loading,
              icon: <ExclamationCircleFilled />,    
              footer: (
                <div style={{float:'inline-end'}}>
                  <Button key="submit" type="primary" loading={loading} onClick={() => Modal.destroyAll()}>
                  {t("No")}
                  </Button>
                </div>     ),
            })
          : Modal.confirm({
              title: "",
              okText: t("Yes"),
              confirmLoading: loading,
              icon: <ExclamationCircleFilled />,
              content: (
                <div>
                  {message?.message?.map((item, index) => (
                    <div key={index}>
                   {item?.message !== "All warning checked" &&
      !(item?.message === "Warning!... You are out of the Geo fence, Do you still want to continue?" && GeoFenceHideMessage) && (
        <p>
          {t(item?.message)} {/* Translate the message */}
        </p>
      )}
                      {(item?.data?.warning && message?.addressList?.length && index === 0) ? (
                        <div style={{ marginBottom: 10 }}>
                          <div>
                            <label style={{ fontWeight: "500" }}>
                              {t("Please select a Location")}{"        "}
                            </label>
                          </div>
                          <Select
                          allowClear={true}
                            showSearch
                            placeholder="Select a Location"
                            optionFilterProp="children"
                            onChange={onChange}
                            style={{ width: "100%" }}
                          >
                            {message?.addressList?.map((location) => (
                           <Option
                           key={location.address}
                           value={location.address}
                           lat={location.lat}
                           lng={location.lng}
                           disabled={!location.active}
                         >
                           {location.address}
                         </Option>
                            ))}
                          </Select>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ),
              onOk() {
                submitSchedule();
                Modal.destroyAll();
              },
              onCancel() {
                console.log("Cancel");
                fetchGeoloc();
                Modal.destroyAll();
              },
            });
      
    }
    const handleNotifySupervisor = () => {
        setSchData((prev) => ({
            ...prev,
            notifySupervisor: !prev.notifySupervisor
        }));
    };

    const submitSchedule = async () => {
        setWarningLoading(true);
        const localStorgeLoc = await GetlocationFromLocalStorage();
        if (localStorgeLoc && !localStorgeLoc.lat) {
            const location = await fetchGeolocation();
            if (location) {
                if (location.latitude && location.longitude) {
                    localStorgeLoc.lat = location.latitude;
                    localStorgeLoc.long = location.longitude;
                    schData.lat = localStorgeLoc.lat;
                    schData.lng = localStorgeLoc.long;
                    localStorage.setItem('location', JSON.stringify(localStorgeLoc));
                }
                else {
                    Toaster("error", t("Your device is unable to pick your location, Please check your device settings"));
                }
            }
        }
         else {
            schData.lat = position.latitude;
            schData.lng = position.longitude;
        }
        if (schData.lng && schData.lat) {
            // if (!schData.eTime || !schData.sTime) {
            //     setWarningLoading(false);
            //     messageApi.error('Start and End Type is required');

            //     return;
            // }
            if(selectedClient.service.configuration.chartingCheck){
                if (schData.chartingValue === '') {
                    setWarningLoading(false);
                    messageApi.error('please select charting value');
                  return;
                }
            }

            if (getHssDetails?.isHssType) {
                if (!schData.hssType) {
                    setWarningLoading(false);
                    messageApi.error(t('Contact Type is required'));

                    return;
                }
            }
            let endPoint = { e: 'emp/end-schedule', c: 'client/end-schedule' };
            let local = ClientEmpData.type ? endPoint[ClientEmpData.type] : '';
            setLoading(false);
            AxiosInstance.post(local, schData)
                .then(({ data }) => {
                    setWarningLoading(false);
                    if (data.success) {
                        Toaster("success", "Success", data.message);
                        setShowEmployeeSignature(true);
                        fetchGeoloc();
                    } else {
                        if (data?.data?.warning) openWarningPops(data.message);
                        else {
                            Toaster("warning", data.message);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    Toaster("error", err.message);
                    setWarningLoading(false);
                });
        }
        else {
            setWarningLoading(false);
            Toaster("error", t("Your device is unable to pick your location, Please check your device settings", ""));
        }
    };
    const openWarningPops = (message) => {
        confirm({
            title: message,
            icon: <ExclamationCircleFilled />,
            onOk() {
                if (message.includes("...")) schData.bypass = true;
                else schData.bypassTC = true;
                submitSchedule();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const backToHomePage = (eDate) => {
        history.push({
            pathname: "/home",
            state: { endDate: eDate },
        });
    }

    const getEmpSignature = (URL) => {
        schData.eSign = URL
        employeeSignature();
    };
    // const getClientSignature = (URL) => {
    //     schData.cSign = URL
    //     clientSignature();
    // };
    const getClientSignature = (type) => {
        schData.cSign = type.getTrimmedCanvas().toDataURL("image/png");
        setIsSignatureEmpty(false); 
        clientSignature();
      };
      const handleDraw = () => {
        setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
      };
    const employeeSignature = () => {
        setWarningLoading(true);
        let endPoint = { e: 'emp/schedule-sign', c: 'client/schedule-sign' };
        let local = ClientEmpData.type ? endPoint[ClientEmpData.type] : '';
        let signatureData = {
            _id: schData._id,
            eSign: schData.eSign,
            lat: schData.lat,
            lng: schData.lng
        }
        AxiosInstance.post(local, signatureData)
            .then(({ data }) => {
                setWarningLoading(false);
                if (data.success) {
                    setShowEmployeeSignature(false);
                    messageApi.success(data.message);
                    // Toaster("success", "Success", data.message);
                    if (data.data && data.data.cSign && data.data.cSignService){
                        setDataForClientPin(data.data._id);
                        setShowClientSignature(true);
                                              }
                    // if (data.data && data.data.cSign && data.data.cSignService) showModalClientPin(data.data._id);
                    else if (data.data && data.data.cSign) {
                        setShowClientSignature(true)
                        setShowClientSignatureBox(true);
                    } 
                    else backToHomePage(schData.eDate)
                } else {
                    Toaster("error", "ATTENTION", data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                Toaster("error", err.message);
                setWarningLoading(false);
            });

        //  setShowEmployeeSignature(false);
        // Toaster("success", "Signature Successfully Saved", "");
    }

    const clientSignature = () => {
        setWarningLoading(true);
        let endPoint = { e: 'emp/client/schedule-sign', c: 'client/client/schedule-sign' };
        let local = ClientEmpData.type ? endPoint[ClientEmpData.type] : '';
        let signatureData = {
            _id: schData._id,
            cSign: schData.cSign,
            lat: schData.lat,
            lng: schData.lng
        }
        AxiosInstance.post(local, signatureData)
            .then(({ data }) => {
                setWarningLoading(false);
                if (data.success) {
                    setShowClientSignature(false);
                    Toaster("success", "Success", data.message);
                    setPinValue("")
                    setDataForClientPin("")
                    backToHomePage(schData.eDate)
                } else {
                    Toaster("info", "ATTENTION", data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                Toaster("error", err.message);
                setWarningLoading(false);
            });
    }

    // const showModalClientPin = (dataId) => {
    //     confirm({
    //         title: 'Do you want client signature? Please enter client signature pin',
    //         icon: <ExclamationCircleFilled />,
    //         content: (
    //             <Input
    //                 placeholder="Enter PIN"
    //                 ref={inputRef}
    //             />
    //         ),
    //         onOk: () => {
    //             setWarningLoading(true);
    //             const pinValue = inputRef.current?.input.value.trim();
    //             if (pinValue) {
    //                 let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
    //                 let local = ClientEmpData.type ? endPoint[ClientEmpData.type] : '';
    //                 let obj = {
    //                     id: dataId,
    //                     pin: pinValue
    //                 };
    //                 AxiosInstance.post(local, obj)
    //                     .then(({ data }) => {
    //                         setWarningLoading(false);
    //                         if (data.success) {
    //                             messageApi.success(data.message);
    //                             setShowClientSignature(true);
    //                         } else {
    //                             messageApi.error(data.message);
    //                             showModalClientPin(dataId); // Show modal again if there's an error
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         console.error(err);
    //                         messageApi.error(err.message);
    //                         setWarningLoading(false);
    //                     });
    //             } else {
    //                 messageApi.error('Please enter a PIN');
    //                 setWarningLoading(false);
    //                 showModalClientPin(dataId);
    //             }

    //         },
    //         onCancel() {
    //             backToHomePage(schData.eDate);
    //         },
    //     });
    // };
    const handlePinChange = (e) => {
        const newPinValue = e.target.value;
        if (newPinValue.length === 4) {
          // If 4 digits are entered, trigger the API call
          setWarningLoading(true); // Set loading state to true
          let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
          let local = ClientEmpData.type ? endPoint[ClientEmpData.type] : '';
          let obj = {
            id: getDataForClientPin,
            pin: newPinValue, // Use the entered PIN value
          };
          AxiosInstance.post(local, obj)
            .then(({ data }) => {
              setWarningLoading(false); // Set loading state to false
              if (data.success) {
                setShowClientSignatureBox(true)
                messageApi.success(data.message);
              } else {
                messageApi.error(data.message);
              }
            })
            .catch((err) => {
              console.error(err);
              messageApi.error(err.message);
              setWarningLoading(false); // Set loading state to false
            });
        }
        setPinValue(newPinValue); // Update PIN value in state
      };

  
    // Handle Input Fields Changes of schData
    const handleChange = (e, field) => {
        if (!e) return;
        let value = e.target ? e.target.value : e; // Check if event or direct value
        if (value.$isDayjsObject) value = dayjs(value).format('hh:mm A')
        if (field !== "notes") {
            setSchData(prevState => ({
                ...prevState,
                [field]: value
            }));
        } else {
            setSchData(prevState => ({
                ...prevState,
                notes: [
                    {
                        note: value,
                        addedBy: ''
                    }
                ]
            }));
        }
    };
    // Change on Qualifier and Note Input Fields
    const onChangeOutcome = (e, name, field, index) => {
        const newValue = e.target ? e.target.value : e;
        // Update the state by mapping over the current outComeList and updating the specific item
        setOutComeList(prevState => {
            // Create a copy of the previous state array
            const updatedList = [...prevState];
            // Update the specific item in the array
            updatedList[index] = {
                ...updatedList[index],
                [field]: newValue
            };
            return updatedList;
        });
        let value = name;
        if (field === "qualifier" && newValue) value = value + ' ' + newValue;
        else value = value + ' ' + outComeList[index].qualifier;
        if (field === "note" && newValue) value = value + ' --- ' + newValue;
        else if (outComeList[index].note) value = value + ' --- ' + outComeList[index].note;
        outcomeSelection({ value, name });
    };

    // Check Outcome Selection Duplication
    const outcomeSelection = (obj) => {
        if (schData.services.length === 0) schData.services = [obj.name]
        else {
            let flag = true
            schData.services = schData.services.map(item => {
                if (item.includes(obj.name)) {
                    item = obj.value
                    flag = false
                }
                return item
            })
            if (flag)
                schData.services.push(obj.value);
            schData.services = [...new Set(schData.services)];
        }
    }

    // Handle Single or Multiple Checkbox Check Uncheck
    const handleCheckboxChange = (task, type = "") => {
        if (type !== "all") {
            // Check if the task is already in the services array
            const isTaskChecked = schData.tasks.includes(task);
            // Update the services state based on the checkbox change
            setSchData(prevState => ({
                ...prevState,
                tasks: isTaskChecked ? prevState.tasks.filter(item => item !== task) : [...prevState.tasks, task] // Check the checkbox
            }));
        } else setSchData(prevState => ({ ...prevState, tasks: task }));
    };

    const resetSpecificFields = () => {
        setSchData(prevState => ({
            ...prevState,
            mileage: "",
            hssType: "",
            notes: [{ note: '', addedBy: '' }],
            services: [],
            tasks: [],
            remoteDirectOptions: ''
        }));
    };
    const [note, setNote] = useState(""); // State to store the note value
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        // let notes = note
        let obj = { "ids": [selectedClient._id], "notes": note || "", "canceledBy": ClientEmpData.type === "e" ? "employee" : "client" }
        cancelSchApi(obj);
        setIsModalOpen(false);
    }
    const closeSignatureModal = () => {
        setShowEmployeeSignature(false);
        setShowClientSignature(false);
        backToHomePage(schData.eDate)
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }
    const handleChangeNotes = (e) => {
        setNote(e.target.value); // Update the note value as the user types
    };
    const showModal = () => {
        setIsModalOpen(true);
        setNote("");
    };
    const cancelSchApi = (obj) => {
        setWarningLoading(true);
        let local = 'schedule/cancel';
        AxiosInstance.post(local, obj)
            .then(({ data }) => {
                setWarningLoading(false);
                if (data.success) {
                    Toaster("success", "Success", data.message);
                    backToHomePage(schData.eDate);
                } else {
                    Toaster("error", data.message);
                }
            })
    }
    const clearCanvas = () => {
        clientSigCanvas.current.clear();
        setIsSignatureEmpty(true);
    };
    return (
      <div style={{ paddingBottom: "70px" }}>
        <Card style={{ margin: 10 }}>
          {selectedClient.scheduleStatus === "New" && (
            <Row
              gutter={16}
              style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
            >
              {/* <Col span={12}>
                        <ArrowLeftOutlined style={{marginTop:'3px', marginRight: '10px', float: 'left', fontSize: '1.3rem', color: 'red' }}  onClick={() => backToHomePage(schData.eDate)}/>
                    </Col> */}

              <Col span={24}>
                <Button
                  type="primary"
                  className="ant-btn-form"
                  style={{ marginRight: "18px", float: "right" }}
                  onClick={showModal}
                >
                  {t("Cancel Schedule")}
                </Button>
              </Col>
            </Row>
          )}
        </Card>
        <Card style={{ margin: 10 }}>
          <Row
            gutter={16}
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              fontWeight: "bold",
            }}
          >
            <Col span={12}>
              {ClientEmpData.type === "c" && selectedClient.empId && (
                <span>
                  {selectedClient.empId.fName} {selectedClient.empId.lName}
                </span>
              )}
              {ClientEmpData.type === "e" && selectedClient.clientId && (
                <span>
                  {selectedClient.clientId.fName}{" "}
                  {selectedClient.clientId.lName}
                </span>
              )}
            </Col>
            <Col span={12}>
              {t("Start Date")}:{" "}
              {dayjs(selectedClient.sDate).add(1, "day").format("MM/DD/YYYY")}
            </Col>
            <Col span={12} style={{ fontWeight: "normal" }}>
              {t("From")}: {selectedClient?.sTime}
            </Col>
            <Col span={12} style={{ fontWeight: "normal" }}>
              {t("To")}:{" "}
              {selectedClient?.scheduleStatus === "Inprogress"
                ? "__ __ __ "
                : selectedClient?.eTime}
            </Col>
            <Col span={12} xs={12} sm={24} md={12} lg={6} xl={12}>
              <div>
                <label style={{ fontWeight: "normal" }}>
                  {t("Start Time")}
                </label>
              </div>
              <TimePicker
                defaultValue={dayjs(
                  selectedClient.aSTime || selectedClient?.sTime,
                  format
                )}
                onChange={(e) => handleChange(e, "sTime")}
                disabled
                format={format}
                placeholder={t("Select Start Time")}
              />
            </Col>
            <Col span={12} xs={12} sm={12} md={12} lg={6} xl={12}>
              <div>
                <label style={{ fontWeight: "normal" }}>{t("End Time")}</label>
              </div>
              <TimePicker
                style={{ marginLeft: "-5px" }}
                width={10}
                defaultValue={
                  selectedClient.scheduleStatus === "Inprogress"
                    ? dayjs()
                    : dayjs(
                        selectedClient.aETime || selectedClient?.eTime,
                        format
                      )
                }
                onChange={(e) => handleChange(e, "eTime")}
                format={format}
                disabled={selectedClient?.startEndTimeRestriction}
                placeholder={t("Select End Time")}
              />
            </Col>
          </Row>
        </Card>
        {/* <div style={{ textAlign: 'center' }}>
                <Spin tip="Loading..." size="large" spinning={loading} />
            </div> */}
        <Spin spinning={warningLoading} fullscreen size="large" />

        {ClientEmpData.type === "e" && ClientEmpData?.breaks?.length > 0 && (
          <Card style={{ margin: "10px" }}>
            <h5 style={{ margin: 10 }}>
              {t("Total number of breaks")} {selectedClient?.breaks?.length}
            </h5>
            <Row gutter={[2, 2]} style={{ marginBottom: "10px" }}>
              {selectedClient?.breaks?.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Card
                    style={{
                      margin: "10px",
                      background: "#f0f2f5",
                      position: "relative",
                      boxShadow: "none",
                    }}
                  >
                    <Meta
                      description={
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "0.75rem",
                            color: "black",
                            marginTop: "10px",
                            padding: "0 14px",
                            marginBottom: "10px",
                          }}
                        >
                          {t("Break In")} {dayjs(item.in).format("hh:mm A")}{" "}
                          {t("Break Out")} {dayjs(item.out).format("hh:mm A")}
                        </div>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        )}

        {serviceList.length ? (
          <Card style={{ margin: "10px" }}>
            <h5 style={{ margin: 10 }}>{t("Services")}</h5>
            <Row gutter={[2, 2]} style={{ marginBottom: "10px" }}>
              {serviceList?.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
                  <Card
                    style={{
                      margin: "10px",
                      background: "#f0f2f5",
                      position: "relative",
                      boxShadow: "none",
                    }}
                  >
                    <Meta
                      // title={
                      //     <div style={{ marginTop: "10px", padding: "0 14px" }}>
                      //         {item.task.split(':').slice(0, 2).join(':')} {/* Take the parts before and including the second colon */}
                      //     </div>
                      // }
                      description={
                        <>
                          <div
                            style={{
                              fontSize: "0.75rem",
                              color: "black",
                              marginTop: "10px",
                              padding: "0 14px",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {item.task.split(":")[0]}:
                            </span>
                            <div>{item.task.split(":").slice(1).join(":")}</div>
                          </div>

                          <div
                            style={{
                              marginTop: 2,
                              padding: "0 14px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.instruction}
                          </div>
                        </>
                      }
                    />
                    {!selectedClient.button && (
                      <div
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          width: "65px",
                          height: "2rem",
                          top: "0px",
                          right: "-8px",
                        }}
                      >
                        <Checkbox
                          checked={schData.tasks.includes(item.task)}
                          onChange={() => handleCheckboxChange(item.task)}
                        ></Checkbox>
                      </div>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null}
        {outComeList.length ? (
          <Card style={{ margin: "10px" }}>
            <h5 style={{ margin: 10 }}>{t("GoalsOutcomeSection")}</h5>
            <Row gutter={[2, 2]}>
              {outComeList?.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
                  <Card
                    style={{
                      margin: "10px",
                      background: "#f0f2f5",
                      position: "relative",
                      boxShadow: "none",
                    }}
                  >
                    <Meta
                      // title={
                      //     <div style={{ marginTop: "10px", padding: "0 14px" }}>
                      //         {item.outcome}
                      //     </div>
                      // }
                      description={
                        <div
                          style={{
                            marginTop: 10,
                            padding: "0 14px",
                            marginBottom: "10px",
                            color: "black",
                          }}
                        >
                          <div
                            style={{ fontWeight: "bold", marginTop: "10px" }}
                          >
                            {item.outcome}
                          </div>
                          <div>
                            <span>{item.instruction}</span>
                          </div>
                          <Flex
                            gap={24}
                            horizontal
                            style={{ marginTop: "5px" }}
                          >
                            <Flex gap={8}>
                              {item.measure_type === "Rating" ||
                              item.measure_type === "Count" ? (
                                <InputNumber
                                  size="small"
                                  disabled={selectedClient.button}
                                  min={1}
                                  max={10}
                                  defaultValue={item.qualifier}
                                  onChange={(e) =>
                                    onChangeOutcome(
                                      e,
                                      item.outcome,
                                      "qualifier",
                                      index
                                    )
                                  }
                                />
                              ) : (
                                <Select
                                  disabled={selectedClient.button}
                                  value={item.qualifier}
                                  onChange={(e) =>
                                    onChangeOutcome(
                                      e,
                                      item.outcome,
                                      "qualifier",
                                      index
                                    )
                                  }
                                  style={{ flex: 1 }}
                                  size="large"
                                >
                                  <Select.Option value="Yes">Yes</Select.Option>
                                  <Select.Option value="No">No</Select.Option>
                                </Select>
                              )}
                            </Flex>
                          </Flex>
                          {!selectedClient.button && (
                            <Row>
                              <Col span={24}>
                                <Input.TextArea
                                  autoSize
                                  placeholder={t("s")}
                                  onChange={(e) =>
                                    onChangeOutcome(
                                      e,
                                      item.outcome,
                                      "note",
                                      index
                                    )
                                  }
                                  style={{ marginTop: "2px" }}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null}

        {selectedClient.scheduleStatus !== "New" && (
          <>
            <Row
              gutter={6}
              style={{
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              {selectedClient.service.configuration.chartingCheck &&
                ClientEmpData.type === "e" && (
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>
                        {t("Add charting time")}
                        <span style={{ color: "red" }}>*</span>
                      </h5>
                    </div>
                    <Select
                      size="large"
                      placeholder={t("Add charting time")}
                      onChange={(e) => handleChange(e, "chartingValue")}
                      value={
                        schData.chartingValue !== ""
                          ? schData.chartingValue
                          : undefined
                      }
                      style={{ borderColor: "#91caff" }}
                      disabled={selectedClient.button}
                    >
                      {chartingAr.map((item, index) => (
                        <Option key={index} value={item.opt}>
                          {item.opt} {item.form}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                )}

              {selectedClient.service.configuration.pdnCheck && (
                <>
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>{t("Temperature")}</h5>
                    </div>
                    <Input
                      placeholder={t("Temperature")}
                      disabled={selectedClient.button}
                      onChange={(e) => handleChange(e.target.value, "temp")}
                      value={selectedClient.temp}
                    />
                  </Col>
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>{t("Resp Rate")}</h5>
                    </div>
                    <Input
                      placeholder={t("Resp Rate")}
                      disabled={selectedClient.button}
                      onChange={(e) => handleChange(e.target.value, "resRate")}
                      value={selectedClient.resRate}
                    />
                  </Col>
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>{t("Pulse Rate")}</h5>
                    </div>
                    <Input
                      placeholder={t("Pulse Rate")}
                      disabled={selectedClient.button}
                      onChange={(e) =>
                        handleChange(e.target.value, "pulseRate")
                      }
                      value={selectedClient.pulseRate}
                    />
                  </Col>
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>{t("BP")}</h5>
                    </div>
                    <Input
                      placeholder={t("BP")}
                      disabled={selectedClient.button}
                      onChange={(e) => handleChange(e.target.value, "bpValue")}
                      value={selectedClient.bpValue}
                    />
                  </Col>
                  <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                    <div>
                      <h5 style={{ margin: 10 }}>02 SAT/02 SET</h5>
                    </div>
                    <Input
                      placeholder="02 SAT/02 SET"
                      disabled={selectedClient.button}
                      onChange={(e) => handleChange(e.target.value, "satSet")}
                      value={selectedClient.satSet}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        )}

        {!selectedClient.button && (
          <Card style={{ margin: 10 }}>
            <Row
              gutter={6}
              style={{
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 10,
                fontWeight: "bold",
              }}
            >
              <Col span={24}>
                <Form layout="inline">
                  {selectedClient.isHssType && (
                    <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Select
                        size={"large"}
                        value={schData.hssType}
                        placeholder="Select HHS Type"
                        suffixIcon={
                          <PhoneTwoTone className="site-form-item-icon" />
                        }
                        onChange={(value) => handleChange(value, "hssType")}
                        style={{
                          width: "99%",
                          marginBottom: 5,
                          marginLeft: "-7px",
                        }}
                      >
                        {schData.hssType ? null : (
                          <Select.Option value="">
                            {"Select Contact Type"}
                          </Select.Option>
                        )}
                        {selectedClient.hssTypeList.map((x, index) => (
                          <Select.Option key={index} value={x}>
                            {x}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  )}
                  {selectedClient.isMileage &&
                    schData.hssType === "Remote Direct" && (
                      <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Select
                          size={"large"}
                          value={schData.remoteDirectOptions}
                          onChange={(value) =>
                            handleChange(value, "remoteDirectOptions")
                          }
                          style={{
                            width: "100%",
                            marginBottom: 5,
                            marginLeft: "-7px",
                          }}
                        >
                          {schData.remoteDirectOptions ? null : (
                            <Select.Option value="">
                              {"Select Remote direct options"}
                            </Select.Option>
                          )}
                          <Select.Option value="Office">Office</Select.Option>
                          <Select.Option value="Community">
                            Community
                          </Select.Option>
                          <Select.Option value="Others">Others</Select.Option>
                        </Select>
                      </Col>
                    )}
                  {selectedClient.isMileage && (
                    <Col span={12} xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Input
                        size="small"
                        value={selectedClient.mileage}
                        onChange={(e) => handleChange(e, "mileage")}
                        prefix={<CarTwoTone />}
                        placeholder="Enter Mileage"
                        style={{
                          width: "100%",
                          marginBottom: 5,
                          marginLeft: "-7px",
                        }}
                      />
                    </Col>
                  )}

                  <Col
                    span={24}
                    style={{ marginTop: "5px", marginLeft: "-7px" }}
                  >
                    <Input.TextArea
                      autoSize
                      value={schData.notes[0].note}
                      onChange={(e) => handleChange(e, "notes")}
                      prefix={<EditOutlined className="site-form-item-icon" />}
                      showCount
                      maxLength={700}
                      placeholder={
                        schData?.notifySupervisor
                          ? t("Enter Note (required)")
                          : selectedClient.isHssType
                          ? t("Case Note Entry")
                          : selectedClient?.service?.configuration?.is_chart
                          ? t("Enter Note (required)")
                          : t("Enter Note")
                      }
                    />
                  </Col>
                  { uType === "e" ?
                  <Col
                    span={24}
                    style={{ marginTop: "5px", marginLeft: "-7px" }}
                  >
                    <Checkbox
                      checked={schData.notifySupervisor}
                      onChange={handleNotifySupervisor}
                      style={{ fontWeight: "normal" }}
                    >
                      Notify Supervisor
                    </Checkbox>
                  </Col>:null}
                  <Col
                    span={24}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={4}
                    xl={4}
                    style={{ marginTop: "30px" }}
                  >
                    <Form.Item>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={onCheckEndWarning}
                        loading={loading}
                        disabled={
                          schData.eTime &&
                          (!selectedClient?.service?.configuration?.is_chart ||
                            (schData.notes[0]?.note &&
                              schData.notes[0].note.trim() !== ""))
                            ? false
                            : true
                        }
                      >
                        {t("EndSchedule")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Card>
        )}

        <Modal
          title={t(
            "Do you want to cancel this schedule? Please provide a reason for schedule cancelation."
          )}
          open={isModalOpen}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          footer={[
            <Button
              key="cancel"
              onClick={handleCancel}
              className="ant-btn-danger"
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="update"
              style={{
                marginTop: "2rem",
                padding: "0 11rem;",
              }}
              className="ant-btn-success"
              onClick={handleOk}
              loading={loading}
              disabled={!note.trim()}
            >
              {t("Okay")}
            </Button>,
          ]}
        >
          <div>
            <TextArea
              showCount
              maxLength={100}
              onChange={handleChangeNotes}
              value={note}
              placeholder={t("Enter Reason")}
              style={{ width: "100%" }}
            />
            {!note.trim() && (
              <p style={{ color: "red", marginTop: "0.5rem" }}>
                {t("Please provide a reason.")}
              </p>
            )}
          </div>
        </Modal>
        {showEmployeeSignature && (
          <SignatureModal
            showSignature={showEmployeeSignature}
            handleCancel={closeSignatureModal}
            type="employee"
            loading={warningLoading}
            isAddNotes={true}
            onDone={getEmpSignature} // Pass the callback function from the parent component
          />
        )}

        <Modal
          title="Client Signature"
          open={showClientSignature}
          onCancel={closeSignatureModal}
          closable={true}
          footer={null}
          maskClosable={false}
        >
          {getDataForClientPin && (
            <div>
              <div>
                <Title level={5} style={{ fontSize: "11px", color: "red" }}>
                  {t("Please enter client signature pin first")}
                </Title>
              </div>
              <div>
                <Input
                  placeholder={t("Enter PIN")}
                  disabled={showClientSignatureBox ? true : false}
                  onChange={handlePinChange}
                  value={getPinValue}
                />
              </div>
            </div>
          )}
          {showClientSignatureBox && (
            <div>
              <div>
                {/* <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
      Client Signature
  </Title> */}
              </div>
              <div style={{ border: "1px dotted #ccc" }}>
                <SignatureCanvas
                  canvasProps={{ width: 460, height: 250 }}
                  ref={clientSigCanvas}
                  onBegin={handleDraw}
                />
              </div>
              <div className="mt-25 text-right">
                <Button
                  style={{ marginRight: "4px" }}
                  className="ant-btn-modal"
                  danger
                  type="primary"
                  onClick={clearCanvas}
                >
                  {t("Clear")}
                </Button>
                <Button
                  className="ant-btn-modal"
                  type="primary"
                  loading={loading}
                  disabled={isSignatureEmpty}
                  onClick={() => getClientSignature(clientSigCanvas.current)}
                >
                  {t("Done")}
                </Button>
              </div>
            </div>
          )}
        </Modal>

        {/* {
                showClientSignature && (
                    <SignatureModal
                        showSignature={showClientSignature}
                        handleCancel={closeSignatureModal}
                        type="client"
                        loading={warningLoading}
                        onDone={getClientSignature} // Pass the callback function from the parent component
                    />
                )
            } */}
      </div>
    );
}
export default ScheduleDetails;
