import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import dayjs from "dayjs";
import { ArrowLeftOutlined, RightCircleTwoTone } from "@ant-design/icons";
import { Card, Col, Row, Tag, Spin, Tabs, Empty } from "antd";
import { useTranslation } from "react-i18next";
const { Meta } = Card;
const { TabPane } = Tabs;

const ClinicalForms = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [formsList, setFormsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carePlanTabs, setCarePlanTabs] = useState([]);

  useEffect(() => {
    let UserInfo = JSON.parse(localStorage.getItem("USER_INFO")) || "";
    if (UserInfo) {
      getCarePlanTabs(UserInfo.companyId._id);
    }
  }, []);

  const getCarePlanTabs = (id) => {
    const types = [];
    types.push({ key: "Incident",label: "Incident",value: "Incident"});
    setCarePlanTabs(types);
    getFormsbyType(types[0].type);
    const local = "emp/careplan-company";
    const params = { cid: id };
    setLoading(true);
    AxiosInstance.get(local, { params })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          data.data.rows.push({ key: "Incident",type: "Incident",value: "Incident"});
          setCarePlanTabs(data.data.rows);
          getFormsbyType(data.data.rows[0].type);
        } else {
          const formsTypes = [
            {
            type:'PCA-Care'}, {
            type:'HMK-Care'}, {
            type:'245D-Basic'}, {
            type:'245D-Intensive'},
            { type: "Incident"},
          ];
          setCarePlanTabs(formsTypes);
          getFormsbyType(formsTypes[0].type);
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
      });
  };

  const getFormsbyType = (type = "") => {
    if(!type) return;
    const local = "clinical/listForms";
    const params = { client_id: id, type: type };
    setLoading(true);
    AxiosInstance.get(local, { params })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          setFormsList(data.data);
        } else {
          setFormsList([]);
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });
  };

  const handleTabClick = (key, tabType) => {
    getFormsbyType(tabType);
  };

  const backToHomePage = () => {
    history.push({
      pathname: "/Clients",
    });
  };

  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div>
      <Spin
        size="large"
        spinning={loading}
        fullscreen
      />
      <Card style={{ margin: 10 }}>
        <Row
          gutter={16}
          style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
        >
          <Col span={24}>
            <ArrowLeftOutlined
              style={{
                marginRight: "10px",
                float: "left",
                fontSize: "1.3rem",
                color: "red",
                cursor: "pointer",
              }}
              onClick={backToHomePage}
            />
            <div>
              <Tabs
                defaultActiveKey="0"
                onChange={(key) => handleTabClick(key, carePlanTabs[key]?.type)}
                centered
                size="large"
                type="card"
              >
                {carePlanTabs.map((tab, index) => (
                  <TabPane tab={tab.type} key={index.toString()}>
                    <Row
                      gutter={[16, 16]}
                      className="mt-3"
                      style={{ margin: "0px 0px" }}
                    >
                      {formsList.map((item, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
                          <Card
                            style={{
                              background: "#f0f2f5",
                              borderLeftWidth: "3px",
                              fontWeight: "bold",
                              position: "relative",
                              boxShadow: "none",
                            }}
                          >
                            <Meta
                              title={
                                <div
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      {" "}
                                      {item.createdBy.fName} {"  "}
                                      {item.createdBy.lName}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "0.7rem",
                                        color: "#8546A7",
                                      }}
                                    >
                                      {" "}
                                      {t('Created At')}: {"          "}
                                      {dayjs(item.createdAt).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <RightCircleTwoTone
                                      onClick={() =>
                                        openLinkInNewTab(item.publicLinkEvv)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              }
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>

                    {formsList.length === 0 && (
                      <Empty description={t('NoDataFound')}/>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </div>
            {/* </Card> */}
            {/* </div> */}
          </Col>
          {/* )} */}
        </Row>
      </Card>
    </div>
  );
};

export default ClinicalForms;
