import AxiosInstance from "../services/axiosInstance";

const USER_INFO = localStorage.getItem("USER_INFO");
export const UserDetails = () => {
  
    if (USER_INFO) {
      try {
        const userInfoObject = JSON.parse(USER_INFO);
        return userInfoObject;
      } catch (error) {
        console.error("Error decoding authentication token:", error);
      }
    }
  
    return null;
  };
  export const RefreshComponet = async (data) => {
    const dirtyBit = localStorage.getItem("dirtyBit");
    const USER_INFO = localStorage.getItem("USER_INFO");
    const uType = localStorage.getItem("type");
    if (dirtyBit) {
      
      if (dirtyBit !== data) {
        const companyId = JSON.parse(USER_INFO).companyId._id;
        
        let endPoint = { e: `evv-configuration/${companyId}/emp`, c: `evv-configuration/${companyId}/client` };
        try {
          const response = await AxiosInstance.get(endPoint[uType]);
          let res = response.data.data;
          localStorage.setItem("dirtyBit", res.dirtyBit);
          const filteredMenu = res.menu.filter((x) => x.value === true);
          localStorage.setItem("Menus", JSON.stringify(filteredMenu));
          localStorage.setItem("Home_Screen", JSON.stringify(res.conditional.home));
          localStorage.setItem("History_Screen", JSON.stringify(res.conditional.history));
          localStorage.setItem("startEndTimeRestriction", JSON.stringify(res.startEndTimeRestriction) || false);
          localStorage.setItem("payrolGracePeriod", JSON.stringify(res.payrolGracePeriod));
          localStorage.setItem("isAllowedPayrollGracePeriod", JSON.stringify(res.isAllowedPayrollGracePeriod));
          localStorage.setItem("byPassEVVCoordinates", JSON.stringify(res.byPassEVVCoordinates));
          localStorage.setItem("GeoFenceHideMessage", JSON.stringify(res.GeoFenceHideMessage));

         if(res.dirtyBit) window.dispatchEvent(new Event("storage"));
        } catch (error) {
          // console.error("Error fetching data:", error.message);
        }
      }
    }
    return null;
  };
  
  