import { Menu} from "antd";
import { NavLink, useLocation,useHistory } from "react-router-dom";
import React,{useState,useEffect} from 'react';
import logo from "../../assets/images/favicon.png";
import { HomeOutlined, HistoryOutlined, ScheduleOutlined,UsergroupAddOutlined,GlobalOutlined,PlusSquareOutlined,CarryOutOutlined,ReloadOutlined , EnterOutlined,DollarOutlined,PhoneOutlined,UserSwitchOutlined  ,ReadOutlined,MedicineBoxOutlined ,AuditOutlined ,ExceptionOutlined  } from '@ant-design/icons';
import LanguagePopup from '../../utility/LanguagePopup';
import { useTranslation } from 'react-i18next';
const customOrder = ['Earning', 'Training & Courses', 'Availability', 'Emar', 'Open Schedular', 'Vacation'];


const Sidenav =({ color }) => {
  const history = useHistory();
  const {t} = useTranslation();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
  const token = localStorage.getItem("rType");
  const [rType, setRType] = useState(token || '');
  const [menuItems, setMenuItems] = useState(JSON.parse(localStorage.getItem('Menus')));
  const [mappedMenuItems, setMappedMenuItems] = useState([]);
  
  useEffect(() => {
    // Event listener for storage changes, registered only once
    // const uType = localStorage.getItem("type");
    const handleStorageChange = () => {
      const uType = localStorage.getItem("type");
    if(uType === "c"){
      const newMenuItems = JSON.parse(localStorage.getItem('Menus'));
      let filteredMenus = newMenuItems?.filter(menu =>
        // menu.name !== "Home" &&
        // menu.name !== "History" &&
        menu.name !== "Schedular" &&
        menu.name !== "Profile" &&
        menu.name !== "Manual Entry" &&
        menu.name !== "Multi Schedular" &&
        menu.name !== "Contact" &&
        menu.name !== "Offline Mode" &&
        // menu.name !== "Medication" &&
        // menu.name !== "CarePlans" &&
        // menu.name !== "Services" &&
        menu.name !== "Profile"
      );
      setMenuItems(filteredMenus);
    }else{
      const newMenuItems = JSON.parse(localStorage.getItem('Menus'));
      let filteredMenus = newMenuItems?.filter(menu =>
        menu.name !== "Home" &&
        menu.name !== "History" &&
        menu.name !== "Schedular" &&
        menu.name !== "Profile" &&
        menu.name !== "Manual Entry" &&
        menu.name !== "Multi Schedular" &&
        menu.name !== "Contact" &&
        menu.name !== "Medication" &&
        menu.name !== "CarePlans" &&
        menu.name !== "Services" &&
        menu.name !== "Offline Mode" &&
        menu.name !== "Profile"
      );
      // Sort filteredMenus based on customOrder
      filteredMenus?.sort((a, b) => {
        return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
      });
      setMenuItems(filteredMenus);
    }
    };
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
      // Remove event listener on cleanup
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  useEffect(() => {
    const uType = localStorage.getItem("type");
    const status = localStorage.getItem("status");
    if(status === "401"){
      history.replace("/sign-in");
      localStorage.clear();
      return;
    }
    if(uType === "c"){
    let filteredMenus = menuItems?.filter(menu =>
      // menu.name !== "Home" &&
      // menu.name !== "History" &&
      menu.name !== "Schedular" &&
      menu.name !== "Profile" &&
      menu.name !== "Manual Entry" &&
      menu.name !== "Multi Schedular" &&
      menu.name !== "Contact" &&
      menu.name !== "Offline Mode" &&
      // menu.name !== "Medication" &&
      // menu.name !== "CarePlans" &&
      // menu.name !== "Services" &&
      menu.name !== "Profile"
    );
    filteredMenus?.sort((a, b) => {
      return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
    });
    // setMenuItems(filteredMenus);
    // Map menuItems and store in separate state variable
    setMappedMenuItems(filteredMenus?.map((item, index) => (
      <Menu.Item key={index}>
      <NavLink to={item.url}>
        <span
          className="icon"
          style={{
            background: page === item.type ? color : "",
          }}
        >
           {getIconComponent(item.icon)}
        </span>
        <span className="label" style={{
          color: page === item.type ? color : "", fontWeight:'bold'
        }}>{t(item.name)}</span>
      </NavLink>
    </Menu.Item>
    )));
  }else{
    let filteredMenus = menuItems?.filter(menu =>
       menu.name !== "Home" &&
       menu.name !== "Offline Mode" &&
      menu.name !== "History" &&
      menu.name !== "Schedular" &&
      menu.name !== "Profile" &&
      menu.name !== "Manual Entry" &&
      menu.name !== "Multi Schedular" &&
      menu.name !== "Contact" &&
      menu.name !== "Medication" &&
      menu.name !== "CarePlans" &&
      menu.name !== "Services" &&
      menu.name !== "Profile"
    );
    filteredMenus?.sort((a, b) => {
      return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
    });
    // setMenuItems(filteredMenus);
    // Map menuItems and store in separate state variable
    setMappedMenuItems(filteredMenus?.map((item, index) => (
      <Menu.Item key={index}>
      <NavLink to={item.url}>
        <span
          className="icon"
          style={{
            background: page === item.type ? color : "",
          }}
        >
           {getIconComponent(item.icon)}
        </span>
        <span className="label" style={{
          color: page === item.type ? color : "", fontWeight:'bold'
        }}>{t(item.label)}</span>
      </NavLink>
    </Menu.Item>
    )));
  }
  }, [menuItems]);

  return (
    <>
    {/* <a href="/home"> */}
      <div className="brand" style={{color:'white'}}>
        <img style={{borderRadius:'50%'}} src={userDetails?.companyId?.logo ? userDetails?.companyId?.logo : logo} alt="logo image" />
{userDetails?.companyId.name ? (
  <span>{userDetails?.companyId.name}</span>
) : <span>RevivingCare</span>
}        

        <div>
        {rType !== 'rp'? userDetails?.fName : userDetails?.rName}


        {userDetails?.title?.name && (
          <small>{`  `}({userDetails?.title?.name})</small>
        )
        }
        </div>
      </div>
      <hr />
      {/* </a> */}
{menuItems && menuItems.length && mappedMenuItems && mappedMenuItems.length ? (
  <Menu theme="light" mode="inline">
  {mappedMenuItems}
</Menu>
//  <Menu theme="light" mode="inline">
//  {menuItems.map((item,index) => (
//    <Menu.Item key={index}>
//      <NavLink to={item.url}>
//        <span
//          className="icon"
//          style={{
//            background: page === item.type ? color : "",
//          }}
//        >
//           {getIconComponent(item.icon)}
//        </span>
//        <span className="label" style={{
//          color: page === item.type ? color : "", fontWeight:'bold'
//        }}>{item.name}</span>
//      </NavLink>
//    </Menu.Item>
//  ))}
// </Menu>
) : <h3>loading</h3>}
{/* <Menu theme="light" mode="inline">
  
<Menu.Item key="1">
  <NavLink to="/home">
    <span
      className="icon"
      style={{
        background: page === "home" ? color : "",
      }}
    >
     <HomeOutlined  />
    </span>
    <span className="label" style={{
        color: page === "home" ? color : "", fontWeight:'bold'
      }}>Home</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="3">
  <NavLink to="/history">
    <span
      className="icon"
      style={{
        background: page === "history" ? color : "",
      }}
    >
      <HistoryOutlined />
    </span>
    <span className="label" style={{
        color: page === "history" ? color : "", fontWeight:'bold'
      }}>History</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="11">
  <NavLink to="/emar">
    <span
      className="icon"
      style={{
        background: page === "emar" ? color : "",
      }}
    >
 <MedicineBoxOutlined   />
    </span>
    <span className="label" style={{
        color: page === "emar" ? color : "", fontWeight:'bold'
      }}>Emar</span>
  </NavLink>
</Menu.Item>

{userDetails && userDetails.type === 'e' && (
<>
<Menu.Item key="4">
  <NavLink to="/schedular">
    <span
      className="icon"
      style={{
        background: page === "schedular" ? color : "",
      }}
    >
   <ScheduleOutlined />
    </span>
    <span className="label"  style={{
        color: page === "schedular" ? color : "", fontWeight:'bold'
      }}>Schedular</span>
  </NavLink>
</Menu.Item>


<Menu.Item key="5">
  <NavLink to="/manual">
    <span
      className="icon"
      style={{
        background: page === "manual" ? color : "",
      }}
    >
 <EnterOutlined />
    </span>
    <span className="label"    style={{
        color: page === "manual" ? color : "", fontWeight:'bold'
      }}>Manual Entry</span>
  </NavLink>
</Menu.Item>


<Menu.Item key="6">
  <NavLink to="/multi">
    <span
      className="icon"
      style={{
        background: page === "multi" ? color : "",
      }}
    >
 <ScheduleOutlined />
    </span>
    <span className="label"  style={{
        color: page === "multi" ? color : "", fontWeight:'bold'
      }}>Multi Scheduler</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="7">
  <NavLink to="/earning">
    <span
      className="icon"
      style={{
        background: page === "earning" ? color : "",
      }}
    >
 <DollarOutlined  />
    </span>
    <span className="label"  style={{
        color: page === "earning" ? color : "", fontWeight:'bold'
      }}>Earning</span>
  </NavLink>
</Menu.Item>


<Menu.Item key="8">
  <NavLink to="/traningCourses">
    <span
      className="icon"
      style={{
        background: page === "traningCourses" ? color : "",
      }}
    >
 <ReadOutlined />
    </span>
    <span className="label" style={{
        color: page === "traningCourses" ? color : "", fontWeight:'bold'
      }}>Training & Courses</span>
  </NavLink>
</Menu.Item>


<Menu.Item key="9">
  <NavLink to="/openScheduler">
    <span
      className="icon"
      style={{
        background: page === "openScheduler" ? color : "",
      }}
    >
 <ReadOutlined />
    </span>
    <span className="label" style={{
        color: page === "openScheduler" ? color : "", fontWeight:'bold'
      }}>Open Scheduler</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="10">
  <NavLink to="/availability">
    <span
      className="icon"
      style={{
        background: page === "availability" ? color : "",
      }}
    >
 <AuditOutlined  />
    </span>
    <span className="label" style={{
        color: page === "availability" ? color : "", fontWeight:'bold'
      }}>Availability</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="15">
  <NavLink to="/clients">
    <span
      className="icon"
      style={{
        background: page === "clients" ? color : "",
      }}
    >
 <UsergroupAddOutlined />
    </span>
    <span className="label"
     style={{
      color: page === "clients" ? color : "", fontWeight:'bold'
    }}>Clients</span>
  </NavLink>
</Menu.Item>
<Menu.Item key="22">
  <NavLink to="/vacation">
    <span
      className="icon"
      style={{
        background: page === "vacation" ? color : "",
      }}
    >
      <ExceptionOutlined  />
    </span>
    <span className="label" style={{
        color: page === "vacation" ? color : "", fontWeight:'bold'
      }}>Vacation</span>
  </NavLink>
</Menu.Item>
</>
)}
{userDetails && userDetails.type === 'c' && (
<>
<Menu.Item key="12">
  <NavLink to="/carePlans">
    <span
      className="icon"
      style={{
        background: page === "carePlans" ? color : "",
      }}
    >
 <CarryOutOutlined  />
    </span>
    <span className="label"
    style={{
      color: page === "carePlans" ? color : "", fontWeight:'bold'
    }}>CarePlans</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="13">
  <NavLink to="/medication">
    <span
      className="icon"
      style={{
        background: page === "medication" ? color : "",
      }}
    >
 <MedicineBoxOutlined />
    </span>
    <span className="label"
     style={{
      color: page === "medication" ? color : "", fontWeight:'bold'
    }}>Medication</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="14">
  <NavLink to="/Services">
    <span
      className="icon"
      style={{
        background: page === "Services" ? color : "",
      }}
    >
 <ReloadOutlined  />
    </span>
    <span className="label"
     style={{
      color: page === "Services" ? color : "", fontWeight:'bold'
    }}>Services</span>
  </NavLink>
</Menu.Item>
</>
)}
<Menu.Item key="2">
  <NavLink to="/contact">
    <span
      className="icon"
      style={{
        background: page === "contact" ? color : "",
      }}
    >
     <PhoneOutlined   />
    </span>
    <span className="label" style={{
        color: page === "contact" ? color : "", fontWeight:'bold'
      }}>Contact us</span>
  </NavLink>
</Menu.Item>

<Menu.Item key="16">
  <NavLink to="/profile">
    <span
      className="icon"
      style={{
        background: page === "profile" ? color : "",
      }}
    >
     <UserSwitchOutlined  />
    </span>
    <span className="label" style={{
        color: page === "profile" ? color : "", fontWeight:'bold'
      }}>User Profile</span>
  </NavLink>
</Menu.Item>
</Menu> */}
{/* } */}
    </>
  );
}
const getIconComponent = (iconName) => {
  switch(iconName) {
    case '<HomeOutlined />':
      return <HomeOutlined />;
    case '<HistoryOutlined />':
      return <HistoryOutlined />;
    case '<EnterOutlined />':
      return <EnterOutlined />;
    case '<MedicineBoxOutlined />':
      return <MedicineBoxOutlined />;
    case '<ScheduleOutlined />':
      return <ScheduleOutlined />;
    case '<DollarOutlined />':
      return <DollarOutlined />;
    case '<ReadOutlined />':
      return <ReadOutlined />;
    case '<AuditOutlined />':
      return <AuditOutlined />;
    case '<UsergroupAddOutlined />':
      return <UsergroupAddOutlined />;
    case '<PhoneOutlined />':
      return <PhoneOutlined />;
    case '<UserSwitchOutlined />':
      return <UserSwitchOutlined />;
    case '<CarryOutOutlined />':
      return <CarryOutOutlined />;
    case '<ReloadOutlined />':
      return <ReloadOutlined />;
    case '<ExceptionOutlined/>':
      return <ExceptionOutlined  />;
    case '<PlusSquareOutlined/>':
      return <PlusSquareOutlined />;
    default:
      return null;
  }
}
export default Sidenav;
